import './index.css';
import 'swagger-ui/dist/swagger-ui.css';

import SwaggerUI from 'swagger-ui'

SwaggerUI({
  url: '/swagger',
  dom_id: '#swagger-ui',
  deepLinking: true,
  presets: [
    SwaggerUI.presets.apis
  ],
  plugins: [
    SwaggerUI.plugins.DownloadUrl
  ]
})
